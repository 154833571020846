import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  collection,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";

import { firestore } from "../../../firebase/config";
import {
  BackButtonContainer,
  GreenHeader,
  GreenP,
  RedHeader,
} from "../../../global.styles";
import Card from "../../UI/card/card.component";
import InnerCard from "../../UI/inner-card/inner-card.component";
import BookingStatus from "../../bookings/booking-status/booking-status.component";
import Spinner from "../../UI/spinner/spinner.component";
import { RootState } from "../../../redux/store";
import { BookingType } from "../../../redux/bookings/bookings.types";

const JobsHistory = () => {
  const navigate = useNavigate();

  const isFirstMount = useRef(true); // Track initial mount to prevent errors on double mount

  const { userLoading, userError, currentUser } = useSelector(
    (state: RootState) => state.user
  );

  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobsError, setJobsError] = useState("");
  const [jobs, setJobs] = useState<BookingType[]>([]);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(); // To track the last document for pagination
  const [paginationOn, setPaginationOn] = useState(false);

  const fetchJobs = useCallback(
    async (startAfterDoc: DocumentData | null = null) => {
      setJobsLoading(true);
      setJobsError("");
      if (currentUser && currentUser.driversData && currentUser.driverNumber) {
        try {
          let jobsQuery = query(
            collection(firestore, "bookings"),
            where("driverNumber", "==", currentUser.driverNumber),
            where("completed", "==", true),
            orderBy("pickupTimestamp", "desc"),
            limit(10)
          );
          if (startAfterDoc) {
            jobsQuery = query(jobsQuery, startAfter(startAfterDoc));
          }
          const jobsSnapshot = await getDocs(jobsQuery);
          const newJobs = jobsSnapshot.docs.map((docSnapshot) => {
            const data = docSnapshot.data();
            return {
              ...data,
              //transforming timestamps from non-serializable to milliseconds number
              createdAt: data.createdAt ? data.createdAt.toMillis() : null, // Check if createdAt exists
              acceptedAt: data.acceptedAt ? data.acceptedAt.toMillis() : null, // Check if acceptedAt exists
              pickedUpAt: data.pickedUpAt ? data.pickedUpAt.toMillis() : null, // Check if pickedUpAt exists
              //convert pickupGeoPoint into serializable object for Redux store
              pickupGeoPoint: {
                latitude: docSnapshot.data()!.pickupGeoPoint.latitude,
                longitude: docSnapshot.data()!.pickupGeoPoint.longitude,
              },
              id: docSnapshot.id,
            } as BookingType;
          });
          if (jobsSnapshot.docs.length > 0) {
            setJobs((prevJobs) =>
              prevJobs ? [...prevJobs, ...newJobs] : newJobs
            );
            // Update lastVisible for pagination
            setLastVisible(jobsSnapshot.docs[jobsSnapshot.docs.length - 1]);
          }
          if (jobsSnapshot.docs.length > 9) {
            setPaginationOn(true);
          } else {
            setPaginationOn(false);
          }
        } catch (error) {
          if (error instanceof Error) {
            setJobsError(error.message);
          } else {
            setJobsError("Error fetching Jobs");
          }
        } finally {
          setJobsLoading(false);
        }
      } else {
        navigate("/");
      }
    },
    [currentUser, navigate]
  );

  //initial fetch
  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false; // Set to false after the first mount
      fetchJobs();
    }
  }, [fetchJobs]);

  // Infinite scroll logic
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastBookingRef = useRef<HTMLDivElement | null>(null); // Reference to the last booking element
  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
    const observer = new IntersectionObserver(
      (entries) => {
        if (paginationOn && entries[0].isIntersecting && lastVisible) {
          fetchJobs(lastVisible);
        }
      },
      { rootMargin: "200px" }
    );
    observerRef.current = observer; // Assign the observer to the ref
    // Observe the target element only if it exists
    if (lastBookingRef.current) {
      observer.observe(lastBookingRef.current);
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [fetchJobs, lastVisible, paginationOn]);

  if (userLoading) return <Spinner />;

  return (
    <Card>
      <BackButtonContainer>
        <Link to="/drivers/driver-panel">
          <h3>&larr; Driver Menu</h3>
        </Link>
      </BackButtonContainer>
      <GreenHeader>Jobs History</GreenHeader>
      {jobsError && <RedHeader>{jobsError}</RedHeader>}
      {userError && <RedHeader>{userError}</RedHeader>}
      {jobs.length === 0 && !jobsError && (
        <GreenP>There are no jobs in Your jobs hisory yet...</GreenP>
      )}
      {!jobsError &&
        jobs.length > 0 &&
        jobs.map((job, index) => {
          //server stores dates in UTC, it doesn't care about winter/summer time
          //if you use server timestamp to interract with client timestamps convert it like this:
          // const serverPickupTime = new Date(booking.pickupTimestamp!);
          // const clientPickupTimestamp = serverPickupTime.setTime(
          //   serverPickupTime.getTime() +
          //     serverPickupTime.getTimezoneOffset() * 60 * 1000
          // );
          return (
            <div
              key={job.id}
              ref={index === jobs.length - 1 ? lastBookingRef : null} // Assign ref conditionally for observer to fetch more bookings
            >
              <InnerCard
                onClick={() => {
                  navigate("/drivers/completed-job-details", {
                    state: {
                      job,
                    },
                  });
                }}
              >
                <div>
                  <p>{job.date}</p>
                  <p>
                    <strong>{job.time}</strong>
                  </p>
                </div>
                <BookingStatus booking={job} />
              </InnerCard>
            </div>
          );
        })}
      {jobsLoading && <Spinner />}
    </Card>
  );
};

export default JobsHistory;
