import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  collection,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { FaDog } from "react-icons/fa";

import Card from "../../UI/card/card.component";
import { RootState } from "../../../redux/store";
import Spinner from "../../UI/spinner/spinner.component";
import {
  AmberP,
  BackButtonContainer,
  GreenHeader,
  GreenP,
  PushToLeftColumn,
  PushToRightColumn,
  RedHeader,
  SpaceAroundArea,
} from "../../../global.styles";
import Button from "../../UI/button/button.component";
import FormInput from "../../UI/form-input/form-input.component";
import RadioButton from "../../UI/radio-buttons/radio-button";
import {
  defineVehicleType,
  getTodaysDateTimestamp,
  transformTimestampDateOnly,
} from "../../../util-functions";
import { requestGeoPoint } from "../../../firebase/bookings";
import { firestore } from "../../../firebase/config";
import { BookingType } from "../../../redux/bookings/bookings.types";
import InnerCard from "../../UI/inner-card/inner-card.component";

const PublicJobSearch = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const driversHash = searchParams.get("driversHash");
  const area = searchParams.get("area");
  const date = searchParams.get("date");

  const { userLoading, userError, currentUser } = useSelector(
    (state: RootState) => state.user
  );
  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  const [searchError, setSearchError] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchComplete, setSearchComplete] = useState(false);
  const [searchAddress, setSearchAddress] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [searchArea, setSearchArea] = useState("5");
  const [searchResult, setSearchResult] = useState<BookingType[]>([]);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(); // To track the last document for pagination
  const [paginationOn, setPaginationOn] = useState(false);
  const [nextPageLoading, setNextPageLoading] = useState(false);

  const timeStampNow = new Date().getTime();

  //initial fetch of first up to 10 jobs
  useEffect(() => {
    //this boolean is used for cleanup function
    //explanation - https://devtrium.com/posts/async-functions-useeffect
    //or - https://blog.logrocket.com/understanding-react-useeffect-cleanup-function/
    let isSubscribed = true;
    //check if we have searching parameters
    if (!searchComplete && approvedDriver && driversHash && area && date) {
      //set drivers GeoHash short based on the selected area
      const driversGeoHashShort = driversHash.substring(0, Number(area));
      //function that fetches the results
      const searchJobs = async () => {
        setSearchResult([]);
        setSearchLoading(true);
        const jobsColRef = collection(firestore, "bookings");
        let jobsQuery = query(
          jobsColRef,
          where("driverNumber", "==", ""),
          where("searchDate", "==", date),
          where(
            `pickupHashNeighbours${area}`,
            "array-contains",
            driversGeoHashShort
          ),
          where("vehicleType", "in", approvedDriver.selectedVehicleTypes),
          where("completed", "==", false),
          orderBy("pickupTimestamp"),
          limit(10)
        );
        if (!approvedDriver.petFriendly) {
          jobsQuery = query(jobsQuery, where("hasPet", "==", false));
        }
        const jobsSnapshot = await getDocs(jobsQuery);
        const fetchedJobs = jobsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            //transforming timestamps from non-serializable to milliseconds number
            createdAt: data.createdAt ? data.createdAt.toMillis() : null, // Check if createdAt exists
            acceptedAt: data.acceptedAt ? data.acceptedAt.toMillis() : null, // Check if acceptedAt exists
            pickedUpAt: data.pickedUpAt ? data.pickedUpAt.toMillis() : null, // Check if pickedUpAt exists
            //convert pickupGeoPoint into serializable object for Redux store
            pickupGeoPoint: {
              latitude: data.pickupGeoPoint.latitude,
              longitude: data.pickupGeoPoint.longitude,
            },
          } as BookingType;
        });
        if (isSubscribed) {
          setSearchLoading(false);
          setSearchError("");
          setSearchComplete(true);
          setSearchResult(fetchedJobs);
          //Set lastVisible for pagination
          if (fetchedJobs.length > 0) {
            setLastVisible(jobsSnapshot.docs[jobsSnapshot.docs.length - 1]);
          }
          //set paginationOn if we have 10 or more jobs
          if (fetchedJobs && fetchedJobs.length > 9) {
            setPaginationOn(true);
          }
        }
      };
      searchJobs().catch((error) => {
        setSearchError("Error with a jobs request");
        // console.log("====================================");
        // console.log(error);
        // console.log("====================================");
        setSearchLoading(false);
        setSearchComplete(true);
      });
    }
    return () => {
      isSubscribed = false;
    };
  }, [approvedDriver, area, date, driversHash, searchComplete]);

  // Infinite scroll logic, lots of repetetive code, but it works! As I write this - time is money!
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastJobRef = useRef<HTMLDivElement | null>(null); // Reference to the last booking element
  useEffect(() => {
    let isSubscribed = true;
    //check if initial search is complete and we have searching parameters
    if (
      searchComplete &&
      approvedDriver &&
      driversHash &&
      area &&
      date &&
      paginationOn &&
      lastVisible
    ) {
      //set drivers GeoHash short based on the selected area
      const driversGeoHashShort = driversHash.substring(0, Number(area));
      //function that fetches the results
      const fetchNextTenJobs = async () => {
        setNextPageLoading(true);
        const jobsColRef = collection(firestore, "bookings");
        let jobsQuery = query(
          jobsColRef,
          where("driverNumber", "==", ""),
          where("searchDate", "==", date),
          where(
            `pickupHashNeighbours${area}`,
            "array-contains",
            driversGeoHashShort
          ),
          where("vehicleType", "in", approvedDriver.selectedVehicleTypes),
          where("notCovered", "==", false),
          orderBy("pickupTimestamp"),
          limit(10),
          startAfter(lastVisible)
        );
        if (!approvedDriver.petFriendly) {
          jobsQuery = query(jobsQuery, where("hasPet", "==", false));
        }
        const jobsSnapshot = await getDocs(jobsQuery);
        const nextFetchedJobs = jobsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            //transforming timestamps from non-serializable to milliseconds number
            createdAt: data.createdAt ? data.createdAt.toMillis() : null, // Check if createdAt exists
            acceptedAt: data.acceptedAt ? data.acceptedAt.toMillis() : null, // Check if acceptedAt exists
            pickedUpAt: data.pickedUpAt ? data.pickedUpAt.toMillis() : null, // Check if pickedUpAt exists
            //convert pickupGeoPoint into serializable object for Redux store
            pickupGeoPoint: {
              latitude: data.pickupGeoPoint.latitude,
              longitude: data.pickupGeoPoint.longitude,
            },
          } as BookingType;
        });
        if (isSubscribed) {
          setNextPageLoading(false);
          setSearchError("");
          setSearchResult((prevJobs) => [...prevJobs, ...nextFetchedJobs]);
          //Set lastVisible for pagination
          if (nextFetchedJobs.length > 0) {
            setLastVisible(jobsSnapshot.docs[jobsSnapshot.docs.length - 1]);
          }
          //if the next chunk/page contains less than 10 documents, stop pagination and infinite scroll
          if (nextFetchedJobs.length < 10) {
            setPaginationOn(false);
          }
        }
      };
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      const observer = new IntersectionObserver(
        (entries) => {
          if (paginationOn && entries[0].isIntersecting && lastVisible) {
            fetchNextTenJobs();
          }
        },
        { rootMargin: "200px" }
      );
      observerRef.current = observer; // Assign the observer to the ref
      // Observe the target element only if it exists
      if (lastJobRef.current) {
        observer.observe(lastJobRef.current);
      }
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      isSubscribed = false;
    };
  }, [
    approvedDriver,
    driversHash,
    area,
    date,
    searchComplete,
    paginationOn,
    lastVisible,
  ]);

  const searchAddressChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchAddress(event.target.value);
  };
  const searchDateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchDate(event.target.value);
  };
  const searchAreaChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchArea(event.target.value);
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!approvedDriver) return;
    if (new Date(searchDate).getTime() < getTodaysDateTimestamp()) {
      setSearchError(
        "Can't search for a job in past! Time travel is not invented yet :)"
      );
      return;
    }
    if (searchArea !== "3" && searchArea !== "4" && searchArea !== "5") {
      setSearchError("Trouble with search area radius...");
      return;
    }
    if (searchAddress === "" || typeof searchAddress !== "string") {
      setSearchError("Please enter the address of the search area");
      return;
    }
    if (searchDate === "" || typeof searchDate !== "string") {
      setSearchError("Please enter a date");
      return;
    }
    setSearchLoading(true);
    //request drivers GeoHash
    let driversGeoHash: string;
    try {
      const driversGeoData = await requestGeoPoint(searchAddress);
      driversGeoHash = driversGeoData.geoHash;
    } catch (error) {
      setSearchError(
        "Problem with the search address. Please be more specific."
      );
      return;
    }
    //send to same url with query params
    navigate(
      `/drivers/public-job-search?driversHash=${driversGeoHash}&area=${searchArea}&date=${searchDate}`
    );
  };

  if (userLoading || approvedDriverLoading || searchLoading) return <Spinner />;

  return (
    <Card>
      <BackButtonContainer>
        <Link to="/drivers/driver-panel">
          <h3>&larr; Driver Menu</h3>
        </Link>
      </BackButtonContainer>
      {userError && <RedHeader>{userError}</RedHeader>}
      {approvedDriverError && <RedHeader>{approvedDriverError}</RedHeader>}
      {currentUser && (!currentUser.readyToDrive || !approvedDriver) && (
        <>
          <GreenHeader>Public Job List</GreenHeader>
          <AmberP>
            This section is available only to Drivers with approved paperwork
          </AmberP>
          <BackButtonContainer>
            <Button onClick={() => navigate("/drivers/application")}>
              Check Driver Profile
            </Button>
          </BackButtonContainer>
        </>
      )}
      {currentUser &&
        currentUser.readyToDrive &&
        approvedDriver &&
        !searchComplete && (
          <>
            <GreenHeader>Search for unassigned jobs</GreenHeader>

            {searchError && <RedHeader>{searchError}</RedHeader>}
            <form onSubmit={submitHandler}>
              <GreenP>
                Please enter the postcode or the address of the area you are
                looking for a job
              </GreenP>
              <FormInput
                label="Search location address"
                id="job_search_address"
                type="text"
                onChange={searchAddressChangeHandler}
                value={searchAddress}
                required
              />
              <SpaceAroundArea>
                <GreenP>Select date:</GreenP>
                <FormInput
                  label=""
                  id="search-date"
                  type="date"
                  onChange={searchDateChangeHandler}
                  value={searchDate}
                  required
                />
              </SpaceAroundArea>
              <GreenP>Select search radius:</GreenP>
              <RadioButton
                label="4 miles"
                id="4miles"
                name="searchArea"
                value="5"
                defaultChecked
                onChange={searchAreaChangeHandler}
              />
              <RadioButton
                label="20 miles"
                id="20miles"
                name="searchArea"
                value="4"
                onChange={searchAreaChangeHandler}
              />
              <RadioButton
                label="140 miles"
                id="140miles"
                name="searchArea"
                value="3"
                onChange={searchAreaChangeHandler}
              />

              {searchError && <RedHeader>{searchError}</RedHeader>}
              <BackButtonContainer>
                <Button type="submit">Search Jobs</Button>
              </BackButtonContainer>
            </form>
          </>
        )}
      {currentUser &&
        currentUser.readyToDrive &&
        approvedDriver &&
        searchComplete && (
          <>
            {searchResult.length === 0 && !searchError ? (
              <>
                <GreenHeader>
                  No results for{" "}
                  {searchDate
                    ? transformTimestampDateOnly(new Date(searchDate).getTime())
                    : date
                    ? transformTimestampDateOnly(new Date(date).getTime())
                    : "the date"}{" "}
                  in the area you are looking for...
                </GreenHeader>
                <GreenP>
                  Please try another date, or search in a different different
                  area
                </GreenP>
              </>
            ) : searchResult.length > 0 && !searchError ? (
              <>
                <GreenHeader>Results for {searchResult[0].date}</GreenHeader>
                {searchResult.map((job, index) => {
                  //server stores dates in UTC, it doesn't care about winter/summer time
                  //if we use server timestamp to interract with client timestamps convert it like this:
                  const serverPickupTime = new Date(job.pickupTimestamp!);
                  const clientPickupTimestamp =
                    serverPickupTime.getTime() +
                    serverPickupTime.getTimezoneOffset() * 60 * 1000;
                  return (
                    <div
                      key={job.id}
                      ref={
                        index === searchResult.length - 1 ? lastJobRef : null
                      } // Assign ref conditionally for observer to fetch more bookings
                    >
                      <InnerCard
                        key={job.id}
                        onClick={() =>
                          navigate("/drivers/public-job-details", {
                            state: {
                              job,
                              searchParams: {
                                driversHash,
                                area,
                                date,
                              },
                            },
                          })
                        }
                      >
                        <PushToLeftColumn>
                          <h3>{job.time}</h3>
                          <p>{job.pickupPostCode}</p>
                          <p>{job.distanceInMiles} miles trip</p>
                          {timeStampNow > Number(clientPickupTimestamp) && (
                            <AmberP>RUNNING LATE!</AmberP>
                          )}
                        </PushToLeftColumn>
                        <PushToRightColumn>
                          <h3>£{job.quotedPrice}</h3>
                          <p>{defineVehicleType(job.vehicleType)}</p>
                          {job.hasPet && (
                            <GreenP>
                              <FaDog /> Pet
                            </GreenP>
                          )}
                        </PushToRightColumn>
                      </InnerCard>
                    </div>
                  );
                })}
                {nextPageLoading && <Spinner />}
              </>
            ) : (
              searchError && <RedHeader>{searchError}</RedHeader>
            )}
            <BackButtonContainer>
              <Button
                onClick={() => {
                  setSearchComplete(false);
                  setSearchAddress("");
                  setSearchArea("5");
                  setSearchDate("");
                  navigate("/drivers/public-job-search");
                }}
              >
                Search Again
              </Button>
            </BackButtonContainer>
          </>
        )}
    </Card>
  );
};

export default PublicJobSearch;
