// Import the functions you need from the SDKs
import {
  signOut,
  onAuthStateChanged,
  User,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
} from "firebase/auth";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import axios, { AxiosError } from "axios";

import { auth, firestore } from "./config";
import { CurrentUserType } from "../redux/user/user.types";
import { VehicleType } from "../redux/vehicles/vehicles.types";

//social sign-in providers
//Google
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account",
});
export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);
//Facebook
const facebookProvider = new FacebookAuthProvider();
export const signInWithFacebookPopup = () =>
  signInWithPopup(auth, facebookProvider);

export const signOutUser = async () => await signOut(auth);

export const getCurrentUser = (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubscribe();
        resolve(userAuth);
      },
      reject
    );
  });
};

export type UserData = {
  createdAt: Timestamp;
  lastUpdate: Timestamp;
  email: string;
};

export const createUserDocumentFromAuth = async (
  userAuth: User
): Promise<void | CurrentUserType> => {
  const userDocRef = doc(firestore, "users", userAuth.uid);

  const userSnapShot = await getDoc(userDocRef);

  //check for boss (admin) permissions
  const { claims } = await userAuth.getIdTokenResult();
  const isBoss = claims?.isBoss === true ? true : false;

  if (!userSnapShot.exists()) {
    const { email, displayName } = userAuth;

    try {
      if (isBoss) {
        await setDoc(userDocRef, {
          email,
          createdAt: serverTimestamp(),
          lastUpdate: serverTimestamp(),
          name: displayName,
          isBoss: isBoss,
        });
      } else {
        await setDoc(userDocRef, {
          email,
          createdAt: serverTimestamp(),
          lastUpdate: serverTimestamp(),
          name: displayName,
        });
      }
      const userSnapShot = await getDoc(userDocRef);
      const fetchedUser = {
        id: userSnapShot.id,
        ...userSnapShot.data(),
        createdAt: userSnapShot.data()!.createdAt.toMillis(),
        lastUpdate: userSnapShot.data()!.lastUpdate.toMillis(),
      };
      if (isBoss) {
        const bossUser = { ...fetchedUser, isBoss };
        return bossUser as CurrentUserType;
      }
      return fetchedUser as CurrentUserType;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Error creating the user");
      }
    }
  }
  const fetchedUser = {
    id: userSnapShot.id,
    ...userSnapShot.data(),
    createdAt: userSnapShot.data()!.createdAt.toMillis(),
    lastUpdate: userSnapShot.data()!.lastUpdate.toMillis(),
  };
  if (isBoss) {
    const bossUser = { ...fetchedUser, isBoss };
    return bossUser as CurrentUserType;
  }
  return fetchedUser as CurrentUserType;
};

export const updateUserName = async (userId: string, name: string) => {
  const userDocRef = doc(firestore, "users", userId);
  try {
    await updateDoc(userDocRef, {
      name,
      lastUpdate: serverTimestamp(),
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error creating the user");
    }
  }
};

export const updateUserPhone = async (userId: string, phoneNumber: string) => {
  const userDocRef = doc(firestore, "users", userId);
  try {
    await updateDoc(userDocRef, {
      phoneNumber,
      lastUpdate: serverTimestamp(),
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error updating phone number");
    }
  }
};

// ---=== DRIVER FUNCTIONS ===---
export const addLicensingAuthority = async (
  userId: string,
  licensingAuthority: string
) => {
  const userDocRef = doc(firestore, "users", userId);
  try {
    await updateDoc(userDocRef, {
      "driversData.licensingAuthority": licensingAuthority,
      "driversData.petFriendly": false,
      lastUpdate: serverTimestamp(),
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error adding Licensing Authority");
    }
  }
};

export const addUserDriverDoc = async (
  userId: string,
  docType: string,
  downloadUrl: string
) => {
  const userDocRef = doc(firestore, "users", userId);
  try {
    //updateDoc will replace data in nested destination
    //setDoc with {merge: true} will affect only specified fields
    //we target nested data to keep expiry date if upload is not first
    await updateDoc(userDocRef, {
      [`driversData.${docType}.pendingUrl`]: downloadUrl,
      lastUpdate: serverTimestamp(),
      waitingDriverApproval: true,
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error adding a new document!");
    }
  }
};

export const addDvlaCheckCode = async (userId: string, checkCode: string) => {
  const userDocRef = doc(firestore, "users", userId);
  try {
    await updateDoc(userDocRef, {
      "driversData.DVLACheckCode.code": checkCode,
      "driversData.DVLACheckCode.pending": true,
      lastUpdate: serverTimestamp(),
      waitingDriverApproval: true,
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error adding DVLA Check Code");
    }
  }
};

export const changeDriverPreferences = async (
  userId: string,
  selectedVehicleTypes: VehicleType[],
  petFriendly: boolean
) => {
  const approvedDriverDocRef = doc(firestore, "approvedDrivers", userId);
  const userDocRef = doc(firestore, "users", userId);
  try {
    await updateDoc(approvedDriverDocRef, {
      selectedVehicleTypes,
      petFriendly,
    });
    // set it also in driversData of user document. It will be used as a default choice
    await updateDoc(userDocRef, {
      "driversData.petFriendly": petFriendly,
      lastUpdate: serverTimestamp(),
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error changing Driver preferences.");
    }
  }
};

export const pauseResumeNewRequests = async (
  userId: string,
  pausedRequests: boolean
) => {
  const approvedDriverDocRef = doc(firestore, "approvedDrivers", userId);
  try {
    await updateDoc(approvedDriverDocRef, {
      pausedRequests,
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error changing  new jobs acceptance for the Driver.");
    }
  }
};

export const acceptJobRequest = async (jobId: string) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const acceptingUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/acceptJob"; //local tesing
      const acceptingUri = "https://acceptjob-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(
        acceptingUri,
        { jobId },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error accepting this booking.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};

export const cancelJobRequest = async (
  jobId: string,
  reasonOfReject: string
) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const cancelingUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/cancelJob"; //local tesing
      const cancelingUri = "https://canceljob-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(
        cancelingUri,
        { jobId, reasonOfReject },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error cancelling job.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};

export const driversNextAction = async (jobId: string) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const nextActionUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/driversNextAction"; //local tesing
      const nextActionUri = "https://driversnextaction-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(
        nextActionUri,
        { jobId },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error progressing this booking.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};

export const driversBackAction = async (jobId: string) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const backActionUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/driversBackAction"; //local tesing
      const backActionUri = "https://driversbackaction-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(
        backActionUri,
        { jobId },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error reverting action for this booking.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};
